(function ($) {
    let $fn = $(".comp_files");

    if ($fn.length) {
        $("[data-category]").on("click",function () {
            let $el = $(this);

            $el.closest(".elm_nav").find(".mod--active").removeClass("mod--active");
            $el.addClass("mod--active");

            //AJAXem nacist soubory z kategorie

        })
    }
})(jQuery);