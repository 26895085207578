(function($) {
    var reInitGCaptcha;

    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LcXi8gUAAAAALW4zX_017a3iawJ8LN1vyb3pEBB").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LcXi8gUAAAAALW4zX_017a3iawJ8LN1vyb3pEBB', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
    }

    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init("html", function(){
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    (function fn_ajaxEvents() {
        let selector = $("[data-lib-fancybox]");

        cssLoaded(function () {
            $.getStyle(cdnjs.fancybox_css);
            $.getScript(cdnjs.fancybox).done(function () {
                $(selector).each(function () {
                    let id = $(this).data("lib-fancybox");
                    $(this).fancybox({
                        selector: `[data-lib-fancybox="${id}"] .lib--fancybox-item`,
                        transitionEffect: "slide",
                        animationEffect: "zoom",
                        caption : function( instance, item ) {
                            return $(this).attr('title');
                        },
                        buttons: [
                            "close"
                        ]
                    });
                });
            });
        });
    })();

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);
        $('[data-linkdownload]').removeClass('mod--active');

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function(){
                    $el.addClass('mod--active');                    
                });
            }
        });
    });

    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }
                });
            }
        });
    });
    
    doc.on('click', '[data-changecategory]', function (e) {
        e.preventDefault();
        let $el = $(this);
        
        $('[data-category]').removeClass('mod--active');        
        $el.find('[data-category]').addClass('mod--active');
        
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date, id: $el.data('id'), 'do': 'changecategory'},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
                
                sr.sync();
            }
        });        
    });
    
    doc.on('click', '[data-more]', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            method: $el.attr('method'),
            url: $el.attr('action'),            
            data: {'do': 'next', 'page': $el.data('page'), 'id': $el.data('category')},
            dataType: 'json',
            success: function (payload) {
                fn_ajaxHandler(payload);
                sr.sync();
            }
        });        
    });
    
    doc.on('change', '[data-change]', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            method: $el.attr('method'),
            url: $el.attr('action'),            
            data: {'do': 'change', 'id': $el.val()},
            dataType: 'json',
            success: function (payload) {
                fn_ajaxHandler(payload);
                sr.sync();
            }
        });        
    });
    
    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }

    if($("[data-lib-scrollbars]").length) {
        $.getStyle(cdnjs.overlay_scrollbars_css);
        $.getScript(cdnjs.overlay_scrollbars).done(function () {
            $("[data-lib-scrollbars]").overlayScrollbars({
                overflowBehavior : {
                    x : "scroll",
                    y : "hidden"
                },
            });
        })
    }

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    doc.on("click","#layout_resize button",function (e) {
        e.preventDefault();
        var data = $(this).data("size"),
            counter = $(this).closest("#layout_resize").data("counter");

        switch(data) {
            case "more": {
                counter++;
                break;
            }
            case "reset": {
                counter = 0;
                break;
            }
            case "less": {
                counter--;
                break;
            }
            default: {
                break;
            }
        }
        $(this).closest("#layout_resize").attr("data-counter",counter).data("counter",counter);
        html.css("font-size",100*(1 + counter/10)+"%");
    })

})(jQuery);