$.fn.nl_google_map = function (event, data) {
    return this.each(function () {
        let $this = $(this);
        if (!$this.length) {
            return false;
        }
        if (event === 'init') {
            nl_google_map_init();
        }
        else if (event === 'markers_add') {
            nl_google_map_markers_add_method(data);
        }

        else if (event === 'markers_remove') {
            nl_google_map_markers_remove_method();
        }

        else if (event === 'markers_reload') {
            nl_google_map_markers_remove_method();
            nl_google_map_markers_add_method();
        }

        else if (event === 'position') {
            nl_google_map_position_method(data);
        }

        else if (event === 'marker_click') {
            nl_google_map_marker_click_method(data);
        }

        function nl_google_map_init() {
            let $zoom = $this.data("zoom"),
                $coords = $this.data('coords').split(/[\s,]+/),
                $markers = $this.data("markers"),
                $scroll = false,
                $markers_temp = [],
                $map_options = {
                    zoom: $zoom,
                    scrollwheel: true,
                    draggable: true,
                    disableDefaultUI: false,
                    clicable: false,
                    center: {
                        lat: parseFloat($coords[0]), lng: parseFloat($coords[1])
                    }
                };

            let $map = new google.maps.Map($this[0], $map_options);

            if ($this.is("[data-scroll]")) {
                $scroll = true;
            }

            google.maps.event.addListener($map, 'click', function () {
                $map.setOptions({
                    scrollwheel: $scroll, draggable: true
                });
            });

            $(document).on("click", function (e) {
                if ($(e.target).closest($this).length === 0) {
                    $map.setOptions({
                        scrollwheel: false, draggable: false
                    });
                }
            });

            function nl_google_map_position(data) {
                let $location = data.split(/[\s,]+/);
                let latLng = new google.maps.LatLng($location[0], $location[1]);
                $map.panTo(latLng);
                $map.setZoom($zoom);
            }

            function nl_google_map_markers_add(callback) {
                let $bounds = new google.maps.LatLngBounds();
                let $infoWindow = new google.maps.InfoWindow({
                    content: "",
                    maxWidth: 190
                });

                if ($this.filter("[data-marker]").length) {
                    let $main_marker_position = new google.maps.LatLng(parseFloat($coords[0]), parseFloat($coords[1]));
                    let $main_marker_image = {
                        url: $this.data("marker"),
                        anchor: new google.maps.Point(17, 46),
                        scaledSize: new google.maps.Size(34, 46)
                    };

                    let $main_marker = new google.maps.Marker({
                        position: $main_marker_position,
                        map: $map,
                        icon: $main_marker_image,
                        zIndex: 9999
                    });

                    $markers_temp.push($main_marker);
                    $bounds.extend($main_marker_position);
                }
                if ($this.filter("[data-markers]").length) {
                    $markers = $this.data("markers");
                    for (let i = 0; i < $markers.length; i++) {
                        let $marker = $markers[i],
                            $position = new google.maps.LatLng($marker.lat, $marker.lng),
                            $image = {
                                url: "/img/marker-small.png",
                                anchor: new google.maps.Point(17, 17),
                                scaledSize: new google.maps.Size(17, 23)
                            },
                            $icon = new google.maps.Marker({
                                position: $position,
                                map: $map,
                                title: $marker.name,
                                icon: $image
                            });


                        $icon.addListener('click', function() {
                            $infoWindow.open($map, $icon);
                        });

                        function showInfo($icon, $marker) {
                            return function () {
                                let link = "";

                                if (typeof $marker.link !== "undefined") {
                                    link = `<div><a href="${$marker.link.href}">${$marker.link.title}</a></div>`;
                                }

                                $map.panTo($position);
                                $infoWindow.setContent(`<div><strong>${$marker.name}</strong></div>${link}`);
                                $infoWindow.open($map,$icon);
                            }
                        }

                        google.maps.event.addListener($icon, 'click', showInfo($icon, $marker));


                        $markers_temp.push($icon);
                        $bounds.extend($position);

                        if (callback) {
                            callback($icon, $markers_temp, $map, $infoWindow);
                        }
                    }

                    google.maps.event.addListenerOnce($map, 'bounds_changed', function () {
                        if (this.getZoom() > $zoom) {
                            this.setZoom($zoom);
                        }
                    });

                    $map.fitBounds($bounds);
                }

                function nl_google_map_markers_remove() {
                    for (let i = 0; i < $markers_temp.length; i++) {
                        $markers_temp[i].setMap(null);
                    }

                    $markers_temp = [];
                }

                window.nl_google_map_markers_remove_method = nl_google_map_markers_remove;
            }

            function nl_google_map_marker_click(index) {
                // google.maps.event.trigger($markers_temp[index], 'click');
                $map.panTo($markers_temp[index].position);
                $map.setZoom(15);
            }

            window.nl_google_map_markers_add_method = nl_google_map_markers_add;
            window.nl_google_map_position_method = nl_google_map_position;
            window.nl_google_map_marker_click_method = nl_google_map_marker_click;
        }
    });
};