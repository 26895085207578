(function($) {
    let timeout,
        fn = $(".part_ui_dropdown");

    fn.on("click","[aria-expanded]",function(e){
        let el = $(this);
        if(el.hasClass("mod--toggled")) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        clearTimeout(timeout);
        function fn_grow_height(elm) {
            if (elm.closest(".part_ui_dropdown").hasClass("mod--dynamic-height")) {
                let wrapper = elm.closest(".part_ui_dropdown").parent(),
                    growDiv = elm.closest(".part_ui_dropdown");
                wrapper.css("height",growDiv.outerHeight());
            }
        }
        $.fn.close = function() {
            let elm = $(this);
            elm.removeClass("mod--toggled").next("[aria-hidden]").attr("aria-hidden","true");
            timeout = setTimeout(function(){
                elm.attr("aria-expanded","false");
                fn_grow_height(elm);
            },300);
        };
        $.fn.open = function() {
            $(this).addClass("mod--toggled").attr("aria-expanded","true").next("[aria-hidden]").focus().removeAttr("aria-hidden");
            fn_grow_height($(this));
        };

        html.one("click", function() {
            el.close();
        });
        if (el.hasClass("mod--toggled")) {
            el.close();
        } else {
            el.open();
        }
        fn.not(el.closest(fn)).find('[aria-expanded="true"]').close();
    });
})(jQuery);