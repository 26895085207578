(function($) {
    let $fn = $("#layout_header"),
        $header = $fn.find(".wrp_header");

    if ($fn.length) {
        $('.ssm-nav').slideAndSwipe();

        if(!$(html).hasClass("ie")){
            let headroom = new Headroom($fn[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        }
        else {
            $fn.addClass("headroom--not-top");
        }
    }

    doc.on("input", "[data-search-autocomplete]", function () {
        let header_search = doc.find("#layout_header").find(".wrp_header_search"),
            header_search_results = doc.find("#layout_header").find(".wrp_header_search_results");

        let results_data = header_search_results.find("[data-results]");
        let query = $(this).val();
        results_data.html("");
        header_search.find(".icon--search").addClass("state--loading");

        $.ajax({
            method: 'get',
            dataType: 'json',
            data: {do: 'search', q: query}
        }).done(function (data) {
            results_data.html("");
            header_search_results.fadeIn(300);
            header_search.find(".icon--search").removeClass("state--loading");

            if (typeof data.results === 'undefined') {
                results_data.append(`
                       <div class="col">
                           <div class="part_ui_heading size--small">${data.lng_not_found}</div>
                       </div>
                   `);
                let resultsAll = $('[data-results-all]');
                resultsAll.find('a').hide();
            } else {
                data.results.forEach(function (item) {
                    results_data.append(`
                            <div class="col" data-search-item>
                                <a href="${item.url}" class="part_ui_link text--weight-semi">${item.name}</a>
                            </div>
                        `)
                });
                let resultsAll = $('[data-results-all]');
                resultsAll.find('a').show();
                resultsAll.find('span').text(data.total);
                let link = resultsAll.find('a').first();
                link.attr('href', resultsAll.data("results-all")+query);
                resultsAll.show();
            }
        });
    });

    doc.on("click", "[data-toggle-search]", function () {
        let header_search = doc.find("#layout_header").find(".wrp_header_search"),
            header_search_results = doc.find("#layout_header").find(".wrp_header_search_results");

        header_search.fadeIn(300);
        header_search.find('input[type="search"]').focus();

        html.on("click", function (e) {
            if ($(e.target).closest('#layout_header').length === 0) {
                header_search.fadeOut(300);
                header_search.find('input[type="search"]').val("").blur();
                header_search_results.fadeOut();
                html.off('click');
            }
        });
    });

    doc.on("click", "[data-close-search]", function (e) {
        let header_search = doc.find("#layout_header").find(".wrp_header_search"),
            header_search_results = doc.find("#layout_header").find(".wrp_header_search_results");

        e.preventDefault();
        header_search.fadeOut(300);
        header_search.find('input[type="search"]').val("").blur();
        header_search_results.fadeOut();
        html.off('click');
    });
})(jQuery);