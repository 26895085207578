(function ($) {
    let $fn = $(".comp_contact_location_map, .comp_contact");

    if ($fn.length) {
        const $main_map = $fn.find("[data-main-map]");
        const $map = $fn.find("[data-map]");
        $.getScript(cdnjs.googlemap).done(function () {
            $main_map.nl_google_map('init');
            $main_map.nl_google_map('markers_add');

            $map.nl_google_map('init');
            $map.nl_google_map('markers_add');

            $fn.on("click","[data-nav-item]",function () {
                let id = $(this).data("nav-item");
                $map.nl_google_map('marker_click',id);
            });

            setTimeout(function () {
                $fn.on("click","[data-nav-item]",function () {
                    if ($(window).width() < 480) {
                        $('html, body').animate({
                            scrollTop: $($fn.find("[data-switch-area]")).offset().top - 40
                        }, 500);
                    }
                });
            },500);

            $fn.find("[data-nav-item=\"0\"]").trigger("click");
        });
    }
})(jQuery);